<template>
	<div class="body" >
		<div>
			<div style="margin-bottom: 10px;">
				<a-form-model layout="inline" :model="search" class="search-form">
					<a-form-model-item>
						<a-radio-group v-model="search.typeFlag" button-style="outline">
					        <a-radio-button value="1">
					          	日
					        </a-radio-button>
					        <a-radio-button value="2">
					          	月
					        </a-radio-button>
					        <a-radio-button value="3">
					          	年
					        </a-radio-button>
				      	</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="起始月分:" v-show="search.typeFlag == 2">
						<a-month-picker v-model="search.startMonth" placeholder="选择月分" :locale="datePickerLocale" style="width: 120px;" @change="onChange()"/>
					</a-form-model-item>
					<a-form-model-item label="结束月分:" v-show="search.typeFlag == 2">
						<a-month-picker v-model="search.endMonth" placeholder="选择月分" :locale="datePickerLocale" style="width: 120px;" @change="onChange()"/>
					</a-form-model-item>
					<a-form-model-item label="起始日期:" v-show="search.typeFlag == 1">
						<a-date-picker v-model="search.startTime" placeholder="选择日期" :locale="datePickerLocale" style="width: 120px;" :format="dateFormat" @change="onChange()" />
					</a-form-model-item>
					<a-form-model-item label="结束日期:" v-show="search.typeFlag == 1">
						<a-date-picker v-model="search.endTime" placeholder="选择日期" :locale="datePickerLocale" style="width: 120px;" :format="dateFormat" @change="onChange()" />
					</a-form-model-item>
					<a-form-model-item label="设备类型:" size="mini">
						<a-select v-model="search.equipmentType" placeholder="请选择" @change="select" style="width: 80px;">
							<a-select-option v-for="item in equipTypeList" v-if="item.id < 4" :key="item.id" :value="item.id">
								{{item.name}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="设备别名:" editable>
						<a-select v-model="search.equipmentId" filterable placeholder="请选择" style="width: 100px;">
							<a-select-option key="全部" value="-1">全部</a-select-option>
							<a-select-option v-for="item in equipmentList" :key="item.id" :value="item.id">
								{{item.name}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" @click="searchFn">查询</a-button>
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" @click="exportFn">导出</a-button>
					</a-form-model-item>
					<a-form-model-item style="float: right;">
						<img :src="bt_chart" @mouseover="changeBt(1)" @mouseout="changeBt(0)" style="cursor: pointer;" @click="toChart" />
					</a-form-model-item>
				</a-form-model>
			</div>
			<div>
				<a-table :loading="loading" :data-source="tableData3" bordered :columns="columns" :pagination="pagination" :scroll="{y: 660}">
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
	import axios from 'axios';
	import moment from 'moment';
	import $ from 'jquery';
	import form from 'jquery-form';
	export default {
		name: 'income_table',
		data() {
			return {
				columns: [{
						title: '设备别名',
						dataIndex: 'equipmentUUID',
						width: 110,
						scopedSlots: {
							customRender: '设备别名'
						},
						ellipsis: true,
						customRender: (equipmentName) => {
							var name = ''
							equipmentName = equipmentName.replace("06", "02");
							this.equipmentList.map(function(equip) {
								if(equip.numbering === equipmentName) {
									if(equip.typeId == 3){
										name = equip.parentEquipmentName + '-' + equip.name;
									} else {
										name = equip.name;
									}
								}
							});
							if(name === ''){
								name = equipmentName;
							}
							return name;
						}
					},
					{
						title: '时间',
						dataIndex: 'date',
						width: 100,
						scopedSlots: {
							customRender: '时间'
						},
						ellipsis: true,
						customRender: (date) => {
							return date.split(" ")[0];
						}
					},
					{
						title: '收益(元)',
						dataIndex: 'todayInCome',
						width: 80,
						scopedSlots: {
							customRender: '当天收益(元)'
						},
						customRender: (todayInCome) => {
							return todayInCome.toFixed(2);
						}
					},
					{
						title: '效率(%)',
						dataIndex: 'efficiency',
						width: 60,
						scopedSlots: {
							customRender: '效率(%)'
						},
						customRender: (efficiency, row, index) => {
              // console.log([efficiency, row.efficiency_new]);
							// return((row.accumulativeDischargeElectricEnergy + row.dischargeAmount) / (row.accumulativeChargeElectricEnergy + row.chargeAmount) * 100).toFixed(1);
              return row.efficiency_new
						}
					},
					{
						title: '放电量-总(kWh)',
						dataIndex: 'dischargeAmount',
						width: 90,
						scopedSlots: {
							customRender: '放电量-总(kWh)'
						},
					},
					{
						title: '充电量-总(kWh)',
						dataIndex: 'chargeAmount',
						width: 90,
						scopedSlots: {
							customRender: '充电量-总(kWh)'
						},
					},
					{
						title: '充电量-尖(kWh)',
						dataIndex: 'sharpPeriodChargeElectricEnergy',
						width: 90,
						scopedSlots: {
							customRender: '充电量-尖(kWh)'
						},
					},
					{
						title: '充电量-峰(kWh)',
						dataIndex: 'peakPeriodChargeElectricEnergy',
						width: 90,
						scopedSlots: {
							customRender: '充电量-峰(kWh)'
						},
					},
					{
						title: '充电量-平(kWh)',
						dataIndex: 'flatPeriodChargeElectricEnergy',
						width: 90,
						scopedSlots: {
							customRender: '充电量-平(kWh)'
						},
					},
					{
						title: '充电量-谷(kWh)',
						width: 90,
						dataIndex: 'valleyPeriodChargeElectricEnergy',
						scopedSlots: {
							customRender: '充电量-谷(kWh)'
						},
					},
					{
						title: '放电量-尖(kWh)',
						width: 90,
						dataIndex: 'sharpPeriodDischargeElectricEnergy',
						scopedSlots: {
							customRender: '放电量-尖(kWh)'
						},
					},
					{
						title: '放电量-峰(kWh)',
						width: 90,
						dataIndex: 'peakPeriodDischargeElectricEnergy',
						scopedSlots: {
							customRender: '放电量-峰(kWh)'
						},
					},
					{
						title: '放电量-平(kWh)',
						width: 90,
						dataIndex: 'flatPeriodDischargeElectricEnergy',
						scopedSlots: {
							customRender: '放电量-平(kWh)'
						},
					},
					{
						title: '放电量-谷(kWh)',
						width: 90,
						dataIndex: 'valleyPeriodDischargeElectricEnergy',
						scopedSlots: {
							customRender: '放电量-谷(kWh)'
						},
					},
				],
				bt_chart: require("@a/images/bt_chart_nomal.png"),
				pagination: {
					defaultPageSize: 50,
					showTotal: total => `共 ${total} 条`,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '100'],
					onShowSizeChange: (current, pageSize) => this.pageSize = pageSize,
				},
				dateFormat: 'YYYY-MM-DD',
				scroll: {
					y: 670
				},
				datePickerLocale: datePickerLocale,
				equipTypeList: [],
				equipmentList: [],
				search: {
					stationId: localStorage.getItem('stationId'),
					signalId: -1,
					equipmentType: 1,
					startMonth: moment().subtract(12, 'month'),
					endMonth: moment(),
					startTime: moment().subtract(1, 'month'),
					endTime: moment(),
					equipmentId: '-1',
					length: 10000000000,
					page: 1,
					typeFlag: '1',
					startIndex: 0
				},
				tableData3: [],
				total: 0,
				showTotal: true,
				loading: false
			}
		},
		mounted() {
			var token = localStorage.getItem("currentUser_token");
			if(!token && typeof(token) != "undefined" && token != 0) {
				this.$router.replace({
					path: '/'
				})
				return;
			}
			var id = localStorage.getItem('stationId');
			if(!id && typeof(id) != "undefined" && id != 0) {
				this.$router.replace({
					path: '/distribution'
				})
			}
			if(this.$route.params.startTime && this.$route.params.endTime ){
				this.search = this.$route.params;
			}
			var this_ = this;
			this_.getEquipTypeList();
			this_.getEquipmentList();
		},
		methods: {
			toChart() {
				this.$router.push({
					name: 'income_chart',
					params: this.search
				});
			},
			changeBt(type) {
				if(type == 0) {
					this.bt_chart = require("@a/images/bt_chart_nomal.png");
				} else if(type == 1) {
					this.bt_chart = require("@a/images/bt_chart_activity.png");
				}
			},
			select(value) {
				this.search.equipmentId = '-1';
				this.getEquipmentList();
			},
			onChange() {
				if(typeof(this.search.startTime) === 'string') {
					this.search.startTime = this.search.startTime.split(" ")[0]
					this.search.startTime = moment(this.search.startTime);
				}
				if(typeof(this.search.endTime) === 'string') {
					this.search.endTime = this.search.endTime.split(" ")[0];
					this.search.endTime = moment(this.search.endTime);
				}
			},
			getEquipTypeList() {
				var this_ = this;
				axios({
					method: 'get',
					url: '/emind/config/equipment/type/list',
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						this_.equipTypeList = d.data.data;
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			getEquipmentList() {
				var this_ = this;
				if(this.displayMethod == 1 || this.search.equipmentType == 1) {
					this.search.equipmentId = '-1';
				}
				var data = {
					stationId: localStorage.getItem('stationId'),
					typeId: this_.search.equipmentType
				}
				axios({
					method: 'post',
					url: '/emind/config/equipment/search_condition',
					data: data
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						this_.equipmentList = d.data.data;
						this_.equipmentList.sort(this_.sortNum);
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
					this_.getList();
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			sortNum(a,b){  
				if(a.typeId == 3 && b.typeId == 3){
					return (a.num-b.num) * 1; 
				} else if(a.typeId == 3){
					return -1;
				} else {
					return a.typeId - b.typeId;
				}
		   	},
			//获取刷新列表数据
			getList() {
				var this_ = this;
				var data = {
					stationId: localStorage.getItem('stationId'),
					equipmentType: this_.search.equipmentType,
					startTime: this_.search.startTime,
					endTime: this_.search.endTime,
					length: this_.search.length,
					page: this_.search.page,
					startIndex: this_.search.startIndex
				};
				if(this_.search.typeFlag == 2){
					if(this.search.startMonth != null && this.search.endMonth != null && this.search.startMonth.toDate().getTime() > this.search.endMonth.toDate().getTime()) {
						this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
						return;
					} else {
						var dataTime = '';
						if(this_.search.startMonth != null) {
							dataTime = this_.search.startMonth.format('YYYY-MM') + "-01 00:00:00";
							data.startTime = dataTime;
						} else {
							data.startTime = '1970-01-01 00:00:00'
						}
						if(this_.search.endMonth != null) {
							dataTime = this_.search.endMonth.subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
							data.endTime = dataTime;
						} else {
							if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {
								
							} else {
								data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
							}
						}
					}
					data.name='%Y-%m';
				} else if(this_.search.typeFlag == 3){
					data.startTime = moment().subtract(10, 'year').format('YYYY') + "-01-01 00:00:00";
					data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
					data.name='%Y';
				} else {
					if(this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
						this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
						return;
					} else {
						var dataTime = '';
						if(this_.search.startTime != null) {
							dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
							data.startTime = dataTime;
						} else {
							data.startTime = '1970-01-01 00:00:00'
						}
						if(this_.search.endTime != null) {
							dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
							data.endTime = dataTime;
						} else {
							if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
								data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
							}
						}
					}
					data.name='%Y-%m-%d';
				}
				if(this.search.equipmentType != 1) {
					data.equipmentId = this_.search.equipmentId;
					data.signalId = -1;
					if(this.search.equipmentType == 2) {
						data.equipmentType = 1;
					}
				}
				data.startIndex = (data.page - 1) * data.length;
				this.loading = true;
				axios({
					method: 'post',
					url: '/emind/history/data/electricity/statistics',
					data: data
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					this_.total = d.data.recordsTotal;
					this_.tableData3 = d.data.data;
					for(var i = 0; i < this_.tableData3.length; i++) {
						this_.tableData3[i].key = i;
						this_.tableData3[i].equipmentUUID = this_.search.equipmentType == 1 ? localStorage.getItem("stationName") : this_.tableData3[i].equipmentUUID;
						this_.tableData3[i].efficiency = ((this_.tableData3[i].accumulativeDischargeElectricEnergy + this_.tableData3[i].dischargeAmount) * 100 / (this_.tableData3[i].accumulativeChargeElectricEnergy + this_.tableData3[i].chargeAmount)).toFixed(2)
            this_.tableData3[i].efficiency_new =  (this_.tableData3[i].dischargeAmount * 100 / this_.tableData3[i].chargeAmount).toFixed(2)
   
            if (this_.tableData3[i].efficiency_new >= 80 && this_.tableData3[i].efficiency_new <= 95) {
               this_.tableData3[i].efficiency_new = this_.tableData3[i].efficiency_new
            }else{
               this_.tableData3[i].efficiency_new = '--'
            }
					}
					this_.loading = false;
				}).catch(function(error) {
					this_.loading = false;
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			searchFn() {
				var this_ = this;
				this_.getList();
			},
			exportExcel(url, options = {}, name) {
				return new Promise((resolve, reject) => {
					axios({
						method: 'post',
						url: url, // 请求地址
						data: options, // 参数
						responseType: 'blob' // 表明返回服务器返回的数据类型
					}).then(
						response => {
							resolve(response.data)
							let blob = new Blob([response.data], {
								type: 'application/vnd.ms-excel'
							})
							var fileName = name;
							if(response.headers['content-disposition'].endsWith('.xls"')) {
								fileName = fileName + '.xls';
							} else if(response.headers['content-disposition'].endsWith('.zip"')) {
								fileName = fileName + '.zip';
							}
							console.log(fileName);
							console.log(blob);
							var file = new File([blob], fileName, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', lastModified: Date.now()});
							console.log(file);
							if(window.navigator.msSaveOrOpenBlob) {
								navigator.msSaveBlob(blob, fileName)
							} else {
								var link = document.createElement('a')
								link.href = window.URL.createObjectURL(blob)
								link.download = fileName
								link.click()
								//释放内存
								window.URL.revokeObjectURL(link.href)
							}
						},
						err => {
							if(error.message != '') {
								this_.$message.error(error.message, 2, function() {});
								return;
							}
						}
					)
				})
			},
			exportFn() {
				var this_ = this;
				var data = {
					stationId: localStorage.getItem('stationId'),
					equipmentType: this_.search.equipmentType,
					startTime: this_.search.startTime,
					endTime: this_.search.endTime,
					length: this_.search.length,
					page: this_.search.page,
					startIndex: this_.search.startIndex,
					signalId: this_.search.typeFlag
				};
				if(this_.search.typeFlag == 2){
					if(this.search.startMonth != null && this.search.endMonth != null && this.search.startMonth.toDate().getTime() > this.search.endMonth.toDate().getTime()) {
						this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
						return;
					} else {
						var dataTime = '';
						if(this_.search.startMonth != null) {
							dataTime = this_.search.startMonth.format('YYYY-MM') + "-01 00:00:00";
							data.startTime = dataTime;
						} else {
							data.startTime = '1970-01-01 00:00:00'
						}
						if(this_.search.endMonth != null) {
							dataTime = this_.search.endMonth.subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
							data.endTime = dataTime;
						} else {
							if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {
								
							} else {
								data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
							}
						}
					}
					data.name='%Y-%m';
				} else if(this_.search.typeFlag == 3){
					data.startTime = moment().subtract(10, 'year').format('YYYY') + "-01-01 00:00:00";
					data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
					data.name='%Y';
				} else {
					if(this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
						this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
						return;
					} else {
						var dataTime = '';
						if(this_.search.startTime != null) {
							dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
							data.startTime = dataTime;
						} else {
							data.startTime = '1970-01-01 00:00:00'
						}
						if(this_.search.endTime != null) {
							dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
							data.endTime = dataTime;
						} else {
							if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
								data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
							}
						}
					}
					data.name='%Y-%m-%d';
				}
				if(this.search.equipmentType != 1) {
					data.equipmentId = this_.search.equipmentId;
					data.signalId = -1;
					if(this.search.equipmentType == 2) {
						data.equipmentType = 1;
					}
				}
				var now = new Date();
				const url = '/emind/history/data/electricity/statistics/export/' + now.getTime();
				this_.exportExcel(url, data, '电量计量-' + now.Format("yyyy-MM-dd_hh-mm-ss"));
			}
		}
	}
</script>

<style>
@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff!important;
    background: transparent !important;
    border-color: #1890ff;
    box-shadow: -1px 0 0 0 #1890ff;
}
.ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(255, 255, 255, 0.65) !important;
    line-height: 30px;
    background: transparent !important;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-left: 0;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
}
</style>